<template>
  <div class="relative inline-block peer" ref="iconRef">
    <ZenniIconsIconQuestionCircle :size="size" class="text-grays-dark self-center peer" />
    <div ref="tooltipRef" class="fixed opacity-0 peer-hover:opacity-100 transition-all duration-300 z-50 max-w-[266px]">
      <div class="flex">
        <div class="w-4 h-3 self-center z-10">
          <svg class="-mr-[2px] " height="14" width="10" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,7 10,14 10,0" style="fill:white;stroke:#999;stroke-width:1" />
            <line x1="10" y1="1" x2="10" y2="13" style="stroke:white;stroke-width:1" />
          </svg>
        </div>
        <div class="z-0 flex flex-col bg-white border border-grays-medium rounded-lg shadow-[0px_4px_8px_0px_rgba(0,0,0,0.06)] p-4 ">
          <div class="font-semibold text-grays-darkest leading-6">
            {{ tooltip?.title }}
          </div>
          <div class="font-base mt-0.5 text-sm leading-5 tracking-[0.015em]">
            {{ tooltip?.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, onBeforeUnmount, onMounted } from '#imports'
import { IconSizeProps } from '@/components/ZenniIcons/IconBase.vue';
const iconRef = ref<HTMLElement | null>(null)
const tooltipRef = ref<HTMLElement | null>(null)
withDefaults(defineProps<
  {
    tooltip?: {
      title?: string
      description?: string
    },
    size: keyof typeof IconSizeProps,
  }
>(), {
  size: IconSizeProps.base,
})

const updateTooltipPosition = () => {
  if (!iconRef.value || !tooltipRef.value) return
  const icon = iconRef.value.querySelector('svg')
  const tooltip = tooltipRef.value
  if (!icon || !tooltip) return
  const iconRect = icon.getBoundingClientRect()
  const tooltipRect = tooltip.getBoundingClientRect()
  tooltip.style.left = `${iconRect.left + 24}px`
  tooltip.style.top = `${iconRect.top - tooltipRect.height / 2 + 8}px`
}

onMounted(() => {
  updateTooltipPosition()
  window.addEventListener("scroll", updateTooltipPosition);
  const modal = document.querySelector('[data-testid="modal"]')
  if (modal) {
    modal.addEventListener("scroll", updateTooltipPosition)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener("scroll", updateTooltipPosition);
  const modal = document.querySelector('[data-testid="modal"]')
  if (modal) {
    modal.removeEventListener("scroll", updateTooltipPosition)
  }
})
</script>