<template>
  <ZenniZnListItem
    v-for="option in options"
    :id="option.value"
    :key="option.value"
    role="option"
    tabindex="0"
    :aria-selected="option.value === selectedOption"
    class="text-nowrap"
    :class="{ 'font-medium': option.value === selectedOption }"
    @click="selectOption(option)"
    @keydown.enter="selectOption(option)"
    @keydown.space="selectOption(option)"
  >
    <label class="flex items-center gap-2 bg-white">
      <ZenniZnRadio
        v-model="selectedOption"
        :name="option.value"
        :value="option.value"
        @update:model-value="(v) => (selectedOption = v)"
      />
      <span
        class="bg-white"
        :class="{
          'font-semibold': option.value === selectedOption,
        }"
        >{{ option.label }}</span
      >
    </label>
  </ZenniZnListItem>
</template>

<script lang="ts" setup>
import { toRefs, computed } from 'vue'
export interface SelectOption {
  label: string
  value: string
}

export interface ZnSelectDropdownProps {
  options: SelectOption[]
  modelValue: string
  title?: string
  label?: string
}

const props = withDefaults(defineProps<ZnSelectDropdownProps>(), {
  options: () => [],
  modelValue: '',
  title: 'Sort by',
  label: () => '',
})
const emit =
  defineEmits<
    (
      event: 'update:modelValue',
      parameter: ZnSelectDropdownProps['modelValue'],
    ) => void
  >()

const { modelValue, options } = toRefs(props)

const selectedOption = computed<string>(() => modelValue.value || '')
const selectOption = (option: SelectOption) => {
  emit('update:modelValue', option.value)
}
</script>
