<template>
  <ZenniZnFilterFilterCategory
    v-if="facet"
    :title="title"
    :count="selectedProxy.length"
  >
    <ul class="grid grid-cols-5 gap-2 px-2">
      <li
        v-for="{ value, label } in facet.values"
        :key="value"
        :aria-label="label"
      >
        <ZenniZnChip
          v-model="selectedProxy"
          class="peer-next-checked:!ring-1 w-full max-w-[80px]"
          :class="{
            'bg-grays-lighter !ring-grays-dark': selectedProxy.includes(value),
          }"
          size="sm"
          :input-props="{ value: value }"
          :data-testid="`filter-chip-${value}`"
          @click="
            () =>
              selectedProxy.includes(value)
                ? (selectedProxy = selectedProxy.filter((v) => v !== value))
                : (selectedProxy = [...selectedProxy, value])
          "
        >
          {{ label }}
        </ZenniZnChip>
      </li>
    </ul>
    <div class="pt-4 text-center">
      <ZenniZnFindYourSizeModal>
        <template #trigger>
          <div class="cursor-pointer space-x-2">
            <a class="!text-grays-darkest font-semibold underline">
              Find your size
            </a>
            <ZnIcon class="!w-6" type="size" size="xs" path="" altText=""/>
          </div>
        </template>
      </ZenniZnFindYourSizeModal>
    </div>
  </ZenniZnFilterFilterCategory>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type { ChipSelectorProps, ChipSelectorEmits } from '~/types/filter'

const props = withDefaults(defineProps<ChipSelectorProps>(), {
  title: '',
})
const emit = defineEmits<ChipSelectorEmits>()

const selectedProxy = computed({
  get: () => props.selected,
  set: (value: ChipSelectorProps['selected']) => emit('update:selected', value),
})
const { title, facet } = toRefs(props)
</script>
